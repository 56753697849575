
    #login {
        background: url(../assets/images/login_bg_1.png) no-repeat 50%;
        background-size: cover;
        width: 100%;
        height: 100vh;
        overflow: auto;
        position: relative;
        min-height: 600px;
        .header-icon {
            position: absolute;
            left: 20px;
            top: 20px;
        }
        .login-content {
            width: 1000px;
            min-width: 1000px;
            background: #fff;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            display: flex;
            align-items: center;
            overflow: hidden;
            box-shadow: 0 0 16px 0 rgba(62,58,234,0.1);
            .content-left {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 50%;
                height: 100%;
                img {
                    max-width: 100%;
                    max-height: 100%;
                }
            }
            .login-form {
                width: 50%;
                height: 100%;
                padding: 40px;
                box-sizing: border-box;
                .login-content-title {
                    font-size:30px;
                    font-family:PingFang SC;
                    font-weight:400;
                    color:rgba(17,34,216,1);
                    margin-bottom: 50px;
                }
                .el-button--primary {
                    width: 100%;
                    background-color: #1122D8;
                    border-color: #1122D8;
                    &:focus, &:hover {
                        background-color: #2838dc;
                        border-color: #2838dc;
                    }
                }
                .el-form-item {
                    margin-bottom: 30px;
                }
                .remember-pwd {
                    color: #343441;
                    /deep/ .el-checkbox__input.is-checked+.el-checkbox__label {
                        color: #1122D8;
                    }
                    /deep/ .el-checkbox__input.is-checked .el-checkbox__inner {
                        background-color: #1122D8;
                        border-color: #1122D8;
                    }
                }
            }
        }
    }
